import React, { Children, Fragment, useCallback } from "react";
import {
  DateField,
  Filter,
  TextField,
  useGetIdentity,
  ReferenceInput,
  ReferenceField,
  BulkDeleteButton,
  FunctionField,
  useGetOne,
} from "react-admin";
import { IfCanAccess, List } from "@react-admin/ra-rbac";
import { CustomDatagrid } from "../../components/react-admin/CustomDatagrid";
import LicensesBulkRemove from "./LicensesBulkRemove";
import LicensesBulkTransfer from "./LicensesBulkTransfer";
import LicensesMerge from "./LicensesMerge";
import { Typography } from "@mui/material";
import LicensesBulkSendReminder from "./LicensesBulkSendReminder";
import { AutocompleteInput } from "../../components/form/AutocompleteInput";
import FormInput from "../../components/form/FormInput";
import { ListActions } from "../../components/react-admin/ListActions";
import PageTitle from "../../components/navigation/PageTitle";
import ListChip from "../../components/fields/ListChip";

const BulkActionButtons = ({ ...props }) => {
  return (
    <>
      {!window.location.pathname.includes("companies") && props.identity?.status === "Revendeur" && (
        <LicensesBulkTransfer identity={props.identity} />
      )}
      <LicensesMerge />
      <LicensesBulkRemove />
      <LicensesBulkSendReminder />
      <IfCanAccess action="delete" resource="licenses">
        <BulkDeleteButton />
      </IfCanAccess>
    </>
  );
};

// Having group_id prop means that component is called from companies edit.
const LicensesList = ({ ...props }) => {
  const { isLoading, identity } = useGetIdentity();
  return isLoading ? null : (
    <>
      <PageTitle text="Licences" createButton={<></>} type="list" breadcrumbListLabel="Liste des licences" />
      <List
        perPage={10}
        debounce={750}
        {...props}
        filter={
          props.group_id
            ? { group_id: props.group_id }
            : ["Client", "Indirect"].includes(identity?.status)
            ? { group_id: identity?.company }
            : {}
        }
        filters={
          <Filter {...props}>
            <FormInput placeholder="Email" name="email" type="search" alwaysOn />
            {!props.group_id && ["Collaborateur", "Revendeur"].includes(identity?.status) && (
              <ReferenceInput
                source="group_id"
                reference="companies"
                filter={identity?.status === "Revendeur" ? { parentCompany: identity?.company } : {}}
                sort={{ field: "company", order: "ASC" }}
                perPage={1000}
                label="Organisation"
              >
                <AutocompleteInput
                  placeholder="Organisation"
                  optionText="company"
                  filterToQuery={(search: any) => ({ company: search })}
                  filledSecondary
                />
              </ReferenceInput>
            )}
            <ReferenceInput
              source="crmGroupId"
              reference="groups"
              filter={
                props.group_id
                  ? { company: props.group_id }
                  : identity?.status !== "Collaborateur"
                  ? { company: identity?.company }
                  : {}
              }
              sort={{ field: "name", order: "ASC" }}
              perPage={100}
              label="Groupe"
            >
              <AutocompleteInput
                placeholder="Groupe"
                optionText="name"
                filterToQuery={(search: any) => ({ name: search })}
                filledSecondary
              />
            </ReferenceInput>
            <FormInput
              placeholder="Etat affectation"
              type="select"
              source="assigned"
              values={[
                { id: "true", label: "Licence affectée" },
                { id: "false", label: "Licence non affectée" },
              ]}
              filledSecondary
              clearable
              label="Etat affectation"
              hideLabel
            />
            <FormInput
              placeholder="Etat connexion"
              type="select"
              source="active"
              values={[
                { id: "true", label: "Connecté" },
                { id: "false", label: "Déconnecté" },
              ]}
              filledSecondary
              clearable
              label="Etat connexion"
              hideLabel
            />
            <FormInput
              placeholder="Etat licence"
              type="select"
              source="disabled"
              values={[
                { id: "both", label: "Toutes les licences" },
                { id: "true", label: "Licences désactivées" },
                { id: "false", label: "Licences actives" },
              ]}
              filledSecondary
              alwaysOn
            />
          </Filter>
        }
        actions={<ListActions />}
        filterDefaultValues={{
          disabled: "both",
        }}
        exporter={false}
        sort={{ field: "createdAt", order: "DESC" }}
        empty={false}
      >
        <Datagrid group_id={props.group_id} identity={identity} />
      </List>
    </>
  );
};

const LicenseAttachedTo = ({ licenseId }: any) => {
  const { data: license } = useGetOne("licenses", { id: licenseId });
  return !license ? null : <span>rattaché à {license.email}</span>;
};

export default LicensesList;
const Datagrid = ({ group_id, identity }: any) => {
  const { data: company } = useGetOne("companies", { id: group_id || identity?.company });
  return (
    <CustomDatagrid
      bulkActionButtons={<BulkActionButtons identity={identity} company={group_id || identity?.company} />}
      // rowStyle={(record: any, index: number) => ({
      //   backgroundColor:
      //     record?.secondaryLicenseIds?.length >= 1 ? "#52adc445" : record?.mainLicenseId ? "#e9b20045" : "white",
      // })}
      isRowSelectable={(record: any) => !record?.mainLicenseId}
    >
      {(!group_id || (company && company.status === "Revendeur")) && (
        <ReferenceField label="Affecté à" source="group_id" reference="companies" link={false}>
          <TextField source="company" />
        </ReferenceField>
      )}
      <FunctionField
        label="Utilisateur"
        render={({ email, mainLicenseId }: any) => {
          return (
            <ListChip
              label={email ? email : mainLicenseId ? <LicenseAttachedTo licenseId={mainLicenseId} /> : "Non affectée"}
              color={email ? "secondary" : mainLicenseId ? "primary" : "warning"}
            />
          );
        }}
      />
      <FunctionField
        label="Nombre de connexions"
        sx={{ width: "100%" }}
        render={({ max_connections }: any) => <Typography>{max_connections || 1}</Typography>}
      />
      <DateField label="Dernière connexion" source="lastConnectionDate" showTime sortable={false} />
      <FunctionField
        label="Statut"
        render={({ disabled }: any) => {
          return <ListChip label={!disabled ? "Active" : "Désactivée"} color={!disabled ? "secondary" : "error"} />;
        }}
      />
      {/* <FunctionField
        label="Dernière connexion"
        render={({ connexions }: any) => {
          return <Typography>{!connexions ? "Active" : "Désactivée"}</Typography>;
        }}
      /> */}
      {identity?.status === "Collaborateur" && <TextField label="ID License" source="id" sortable={false} />}
      <DateField label="Expire le" source="expiration_date" sortable={false} />
    </CustomDatagrid>
  );
};
