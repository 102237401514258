import { object, string, z, array, boolean, number } from "zod";

export const companyDataSchema = object({
  company: string().min(1, { message: "Veuillez entrer un nom d'organisation." }),
  type: string().min(1, { message: "Veuillez sélectionner un type d'organisation." }),
  address: object({
    address: string().min(1, { message: "Veuillez entrer une adresse." }),
    additionalAddress: string().nullish(),
    zipCode: string().min(1, { message: "Veuillez entrer un code postal." }),
    city: string().min(1, { message: "Veuillez entrer une ville." }),
    country: string().min(1, { message: "Veuillez sélectionner un pays." }),
  }),
  siret: string().nullish(),
  vat: string().nullish(),
  sector: string().nullish(),
  phone: string().nullish(),
  email: string().min(1, "Veuillez renseigner une addresse mail").email("Adresse mail invalide"),
});

export const companyAccountSchema = object({
  parentCompany: string().nullish(),
  status: string().min(1, { message: "Veuillez sélectionner un statut client." }),
  prospect: boolean(),
  clientSince: string().nullish(),
  accountStatus: string().nullish(),
  accountManager: string().min(1, "Veuillez renseigner un manager de compte"),
  source: string().nullish(),
  provider_id: number().min(1, "Veuillez renseigner un provider SMS"),
});

export const companySchema = companyDataSchema.and(companyAccountSchema);

export type Company = z.infer<typeof companySchema>;
