import React, { useState, useEffect, useCallback } from "react";
import {
  useNotify,
  useGetIdentity,
  useCreate,
  useRefresh,
  useDataProvider,
  useTheme,
  ReferenceInput,
} from "react-admin";
import FormInput from "../../components/form/FormInput";
import CreateInDialog from "../../components/react-admin/CreateInDialog";
import { Box, InputAdornment, Typography } from "@mui/material";
import { boolean, object, optional, string } from "zod";
import { query, where } from "firebase/firestore";
import api from "../../services/apiWithAuth";
import { useFormContext, useWatch } from "react-hook-form";
import { ArrowLeft, ArrowRight, ArrowRightLeft } from "lucide-react";
import { DoubleArrow } from "@mui/icons-material";
import { AutocompleteInput } from "../../components/form/AutocompleteInput";

const formSchema = object({
  company: string({
    invalid_type_error: "Client requis",
  }).min(1, "Client requis"),
  action: string({
    invalid_type_error: "action requise",
  }).nullish(),
  quantity: string({
    invalid_type_error: "Quantité requise",
  }).min(1, "Quantité requise"),
});

const Form = ({ company }) => {
  const [companyCredits, setCompanyCredits] = useState("");
  const [companyToTransferCredits, setCompanyToTransferCredits] = useState("");
  const [companies, setCompanies] = useState([] as any);
  const dataProvider = useDataProvider();
  const { isLoading, data: identity } = useGetIdentity();
  const [companyToTransfer] = useWatch({ name: ["company"] });
  // const [action] = useWatch({ control: methods.control, name: ["action"] });

  useEffect(() => {
    if (!isLoading) {
      const asyncCall = async () => {
        if (["Revendeur", "Client", "Indirect"].includes(identity?.status)) {
          const getCreditsRes = await api.get("/utils/getCredits/" + identity?.company);
          setCompanyCredits(getCreditsRes.data[0].credits);
        }
      };
      asyncCall();
    }
  }, [isLoading, identity, dataProvider]);

  useEffect(() => {
    if (!isLoading && companyToTransfer && ["Collaborateur", "Revendeur"].includes(identity?.status)) {
      const asyncCall = async () => {
        const getCompanyToTransferCreditsRes = await api.get("/utils/getCredits/" + companyToTransfer);
        setCompanyToTransferCredits(getCompanyToTransferCreditsRes.data[0].credits);
      };
      asyncCall();
    }
  }, [companyToTransfer, isLoading, identity?.company, identity?.status]);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: ["1fr", "1fr", "1fr"],
        gap: 2,
        minWidth: [1, 350],
        // "& .MuiFormControl-root": { width: [1, 300] },
      }}
    >
      {!company ? (
        <ReferenceInput
          required
          source="company"
          reference="companies"
          filter={
            identity?.status === "Collaborateur"
              ? {}
              : {
                  collectionQuery: (c: any) =>
                    query(c, where("id", "!=", identity?.company), where("parentCompany", "==", identity?.company)),
                }
          }
          sort={{ field: "company", order: "ASC" }}
          perPage={100}
        >
          <AutocompleteInput
            label="Organisation"
            optionText="company"
            filterToQuery={(search: any) => ({ company: search })}
            helperText={
              companyToTransfer && companyToTransferCredits ? companyToTransferCredits + " crédits restants" : ""
            }
          />
        </ReferenceInput>
      ) : null}
      <FormInput
        type="select"
        name="action"
        label="Action"
        defaultValue={"add"}
        values={[
          { id: "add", label: "Créditer" },
          { id: "remove", label: "Débiter" },
        ]}
        required
        disabled={isLoading}
      />
      {identity?.status === "Collaborateur" ? (
        <FormInput
          defaultValue={100}
          required
          label="Quantité"
          name="quantity"
          disabled={isLoading}
          InputProps={{
            endAdornment: <InputAdornment position="end">SMS</InputAdornment>,
          }}
        />
      ) : (
        <FormInput
          type="select"
          name="quantity"
          defaultValue={"100"}
          label="Quantité"
          values={[
            { id: "100", label: "100 SMS" },
            { id: "200", label: "200 SMS" },
            { id: "500", label: "500 SMS" },
            { id: "1000", label: "1000 SMS" },
            { id: "2000", label: "2000 SMS" },
            { id: "5000", label: "5000 SMS" },
            { id: "10000", label: "10000 SMS" },
          ]}
          required
          disabled={isLoading}
        />
      )}
    </Box>
  );
};

const CreateTransfer = ({
  instanceKey = -1,
  setInstanceKey = null,
  company = null,
  ...props
}: {
  instanceKey: number;
  setInstanceKey: any;
  company?: any;
}) => {
  const notify = useNotify();
  const { isLoading, data: identity } = useGetIdentity();
  const theme = useTheme();
  const refresh = useRefresh();
  const [companyCredits, setCompanyCredits] = useState<number | null>(null);
  const [ownCompanyCredits, setOwnCompanyCredits] = useState<number | null>(null);

  useEffect(() => {
    if (!isLoading && company && ["Collaborateur", "Revendeur"].includes(identity?.status)) {
      const asyncCall = async () => {
        const companyCreditsRes = await api.get("/utils/getCredits/" + company);
        setCompanyCredits(companyCreditsRes.data[0].credits);
      };
      asyncCall();
    }
  }, [company, isLoading, identity?.company, identity?.status, companyCredits, instanceKey]);

  useEffect(() => {
    if (!isLoading && ["Revendeur"].includes(identity?.status) && identity?.company) {
      const asyncCall = async () => {
        const ownCompanyCredits = await api.get("/utils/getCredits/" + identity?.company);
        setOwnCompanyCredits(ownCompanyCredits.data[0].credits);
      };
      asyncCall();
    }
  }, [company, isLoading, identity?.company, identity?.status, companyCredits, instanceKey]);

  const save = useCallback(
    async ({ quantity, action, company }: any) => {
      const errors = {} as any;
      try {
        if (identity?.status === "Revendeur") {
          const companyToDebit = action === "add" ? identity?.company : company;
          const companyToCredit = action === "add" ? company : identity?.company;
          await api.post("/utils/transferSMS", {
            from: companyToDebit,
            to: companyToCredit,
            amount: quantity,
          });
        } else {
          //Collaborateur
          await api.post("/utils/transferSMS", {
            from: action === "add" ? null : company,
            to: action === "add" ? company : null,
            amount: quantity,
          });
        }
        notify(
          quantity +
            " SMS ont été " +
            (action === "add" ? "crédités à" : "débités de") +
            " l'organisation selectionnée.",
          {
            type: "success",
            messageArgs: { smart_count: 1 },
          }
        );
        if (setInstanceKey) {
          setInstanceKey((key) => key + 1);
        }
        refresh();
      } catch (error) {
        console.log("error", error);
        if (error?.response?.data?.code === 1) {
          notify(error?.response?.data?.error || "Erreur lors du transfert de crédit", {
            type: "error",
            messageArgs: { smart_count: 1 },
          });
        } else {
          notify("Erreur lors du transfert de crédit", {
            type: "error",
            messageArgs: { smart_count: 1 },
          });
        }
        if (setInstanceKey) {
          setInstanceKey((key) => key + 1);
        }
        refresh();
      }
    },
    [identity?.company, identity?.status, notify, refresh, setInstanceKey]
  );

  return isLoading ? (
    <></>
  ) : (
    <CreateInDialog
      key={instanceKey}
      {...props}
      title={
        <Typography variant="bold_2xl" color="common.title">
          Transfert crédits SMS <br />
          {ownCompanyCredits && (
            <Typography variant="bold_sm" color="common.text">
              Vous avez actuellement {ownCompanyCredits} crédits SMS.
            </Typography>
          )}
        </Typography>
      }
      label={
        companyCredits ? (
          <Typography variant="regular_sm">Transférer crédits &#8226; {companyCredits} restants</Typography>
        ) : (
          "Transférer crédits SMS"
        )
      }
      record={company ? { company } : {}}
      icon={<ArrowRightLeft size={18} />}
      confirmLabel="Transférer"
      resource="contacts"
      formSchema={formSchema}
      fullWidthFields
      formProps={{ onSubmit: save }}
    >
      <Form company={company} />
    </CreateInDialog>
  );
};

export default CreateTransfer;
