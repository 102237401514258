import { stringify } from "query-string";
import api from "../apiWithAuth";
import { collection, where, getDocs, query, getDoc, doc } from "firebase/firestore";
import { fbFirestore } from "./firebase.services";

const DataProvider = {
  getList: (resource: string, params: any) => {
    //to talk same language than api
    params.pagination.maxPerPage = params.pagination.perPage;
    delete params.pagination.perPage;
    params.sort.orderBy = params.sort.field;
    params.sort.sort = params.sort.order;
    delete params.sort.field;
    delete params.sort.order;
    const path = window.location.pathname;
    if (path.includes("companies")) {
      const match = path.match(/(?:companies\/)(.*)/);
      const company = match ? match[1] : null;
      params.filter.group_id = params.filter.group_id || company;
    }
    //sanitize boolean filters
    Object.keys(params?.filter).forEach((field) => {
      if (params.filter[field] === "both") {
        delete params.filter[field];
      }
      params.filter[field] = ["true", "false"].includes(params.filter[field])
        ? params.filter[field] === "true"
        : params.filter[field];
    });
    let filters = params.filter;
    // do not result anything for sms if period is custom and start and end are not specified
    if (resource === "sms" && filters?.viewType !== "historic") {
      return Promise.resolve({
        data: [],
        total: 0,
      });
    }
    // const range = [(page - 1) * perPage, page * perPage];
    return api.get(`${resource}`, { params }).then(async ({ data, headers }) => {
      const total = data.meta.count;
      data = data.data;
      if (data.length === 0 || !Array.isArray(data)) return { data: [], total: 0 };
      if (filters) {
        for (const filteredField in filters) {
          if (resource === "templates" && filteredField === "group_access") {
            data = data.filter((item: any) => {
              return filters[filteredField] === item.group_access;
            });
          }
        }
      }
      return {
        data,
        total,
      };
    });
  },

  getOne: (resource: string, params: any) => {
    return api.get(`${resource}/${params.id}`).then(async ({ data }) => {
      if (resource === "licenses") {
        const metaData: any = JSON.parse(data?.crmMetaData || "{}");
        data = {
          ...metaData,
          ...data,
        };
        if (data.email) {
          const q = query(collection(fbFirestore, "users"), where("email", "==", data.email));
          const querySnapshot = await getDocs(q);
          data.userId = querySnapshot.docs[0].id;
        }
        data.id = data.uuid;
      }
      if (resource === "templates") {
        //todo : say it to Enzo probably a bug
        if (typeof data.alert_delay === "string") {
          data.alert_delay = parseInt(data.alert_delay);
        }
        if (typeof data.reminder_delay === "string") {
          data.reminder_delay = parseInt(data.reminder_delay);
        }
        const q = query(collection(fbFirestore, "groups"), where("templates", "array-contains", data.id));
        const querySnapshot = await getDocs(q);
        data.crmGroupId = querySnapshot.empty ? "" : querySnapshot.docs[0].data().id;
      }
      // if (resource === "contacts") {
      //   const q = query(collection(fbFirestore, "groups"), where("contacts", "array-contains", data.id));
      //   const querySnapshot = await getDocs(q);
      //   data.crmGroupId = querySnapshot.empty ? "" : querySnapshot.docs[0].data().id;
      // }
      if (resource === "sms") {
        const q = query(collection(fbFirestore, "users"), where("licenseId", "==", data.license_uuid));
        const querySnapshot = await getDocs(q);
        data.userId = querySnapshot.empty ? "" : querySnapshot.docs[0].id;
        data.group_id = querySnapshot.empty ? "" : querySnapshot.docs[0].data().company;
      }
      return {
        data,
      };
    });
  },
  //! à implémenter
  getMany: (resource: string, params: any) => {
    const query = {
      filter: JSON.stringify({ ids: params.ids }),
    };
    const url = `${resource}?${stringify(query)}`;
    return api.get(url).then(({ data }) => ({ data }));
  },

  getManyReference: (resource: string, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${resource}?${stringify(query)}`;

    return api.get(url).then(({ data }) => ({
      data,
      //total: parseInt(headers.get('content-range').split('/').pop(), 10),
    }));
  },

  create: (resource: string, params: any) => {
    return api.post(`${resource}`, params.data).then(({ data }) => {
      return {
        data: { ...params.data, id: data.id },
      };
    });
  },

  update: async (resource: string, params: any) => {
    if (resource === "licenses") {
      if (params.previousData.userId) {
        const oldUser = await getDoc(doc(fbFirestore, "users", params.previousData.userId as string));
        params.data.oldEmail = oldUser?.data()?.email || "";
      } else {
        params.data.oldEmail = null;
      }
      if (params.data.userId) {
        const user = await getDoc(doc(fbFirestore, "users", params.data.userId as string));
        params.data.email = user?.data()?.email || "";
      } else {
        params.data.email = null;
      }
    }
    return api.patch(`${resource}/${params.id}`, params.data).then(async ({ data }) => {
      if (resource === "licenses") {
        data.id = data.uuid;
      }
      return { data };
    });
  },
  //! for the while available only for licenses
  updateMany: (resource: string, params: any) => {
    return api.patch(`${resource}/${params.ids}`, params.data).then(({ data }) => {
      if (resource === "licenses") {
        data = data.map((raw: any) => ({ ...raw, id: raw.uuid }));
      }
      return { data };
    });
  },

  delete: (resource: string, params: any) => {
    return api.delete(`${resource}/${params.id}`).then(({ data }) => {
      return { data };
    });
  },

  deleteMany: (resource: string, params: any) => {
    return api.delete(`${resource}/${params.ids}`).then(({ data }) => ({ data }));
  },
};

export default DataProvider;
