import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  CreateButton,
  useGetIdentity,
  DateField,
  Filter,
  FunctionField,
  ReferenceField,
  TextField,
  ReferenceInput,
  useListContext,
  useRefresh,
} from "react-admin";
import { List } from "@react-admin/ra-rbac";
import { CustomDatagrid } from "../../components/react-admin/CustomDatagrid";
import { Typography } from "@mui/material";
import PaymentMethodField from "../../components/fields/PaymentMethodField";
import PageTitle from "../../components/navigation/PageTitle";
import { ListActions } from "../../components/react-admin/ListActions";
import FormInput from "../../components/form/FormInput";
import { AutocompleteInput } from "../../components/form/AutocompleteInput";
import ListChip from "../../components/fields/ListChip";

const paymentStatusToColor: any = {
  "Paiement accepté": "success.light",
  "Paiement en cours": "warning.main",
  "En attente": "neutral.main",
  "Paiement en erreur": "error.main",
  Litige: "error.main",
};

// const NextInvoiceField = () => {
//   const { filterValues } = useListContext();
//   const refresh = useRefresh();
//   console.log("filters", filterValues);

//   useEffect(() => {
//     refresh();
//     console.log("refreshed");
//   }, [filterValues.cancelled, refresh]);

//   return filterValues.cancelled ? (
//     <DateField source="cancellationDate" label="Annulé le" showTime />
//   ) : (
//     <FunctionField
//       label="Prochaine facture"
//       render={(record: any) =>
//         record.cancelled
//           ? "aucune"
//           : !record.periodEnd
//           ? ""
//           : new Date(record.periodEnd * 1000).toLocaleDateString("fr")
//       }
//     />
//   );
// };

const SubscriptionsDatagrid = ({ identity, ...props }: any) => {
  const { filterValues } = useListContext();

  return (
    <CustomDatagrid bulkActionButtons={false}>
      {!props.company && ["Collaborateur", "Revendeur"].includes(identity?.status) && (
        <ReferenceField label="Organisation" source="company_id" reference="companies" link={false}>
          <TextField source="company" />
        </ReferenceField>
      )}
      <ReferenceField label="Produit" source="product_id" reference="products" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <TextField label="Quantité" source="quantity" />
      {identity?.status === "Collaborateur" && (
        <FunctionField
          label="Renouvellement"
          render={(record: any) => {
            return record.autoRenewal ? "Automatique" : "Manuel";
          }}
        />
      )}
      <FunctionField
        label="Mode de paiement"
        render={(record: any) => {
          return <PaymentMethodField source={record.autoRenewal ? "paymentMethod" : "manualPaymentMode"} />;
        }}
      />
      {filterValues.cancelled === "true" ? (
        <DateField source="cancellationDate" label="Annulé le" showTime />
      ) : (
        <FunctionField
          label="Prochaine facture"
          render={(record: any) => {
            return record.cancelled
              ? "aucune"
              : !record.periodEnd
              ? ""
              : new Date(record.periodEnd * 1000).toLocaleDateString("fr");
          }}
        />
      )}
      <FunctionField
        label="Montant HT"
        render={(record: any) => Number(record.totals?.totalHt).toFixed(2) + " €"}
        mb={"20px"}
      />
      <FunctionField
        label="Montant TTC"
        render={(record: any) => Number(record.totals?.totalTtc).toFixed(2) + " €"}
        mb={"20px"}
      />
      {filterValues.cancelled !== "true" && (
        <FunctionField
          label="Abonné depuis"
          render={(record: any) => (record.cancelled ? "" : getDurationSince(record.subscriptionDate) + " mois")}
        />
      )}
      <FunctionField
        label="État"
        render={(record: any) => {
          return (
            <ListChip
              label={record.cancelled ? "Annulé" : "En cours"}
              color={record.cancelled ? "error" : "secondary"}
            />
          );
        }}
      />
    </CustomDatagrid>
  );
};

// Having company prop means that component is called from companies edit.
const SubscriptionsList = ({ ...props }) => {
  const { isLoading, data: identity } = useGetIdentity();
  return isLoading ? (
    <></>
  ) : (
    <>
      <PageTitle
        text="Abonnements"
        createButton={
          identity?.status === "Collaborateur" ? (
            <CreateButton label="Nouvel Abonnement" resource="subscriptions" />
          ) : null
        }
        type="list"
        breadcrumbListLabel="Liste des abonnements"
      />
      <List
        disableSyncWithLocation
        perPage={10}
        {...props}
        filter={
          props.company
            ? { company_id: props.company }
            : identity?.status !== "Collaborateur"
            ? { company_id: identity?.company }
            : {}
        }
        filters={
          <Filter {...props}>
            {identity?.status === "Collaborateur" && !props.company && (
              <ReferenceInput
                source="company_id"
                reference="companies"
                sort={{ field: "company", order: "ASC" }}
                perPage={1000}
                label="Rechercher par client"
                alwaysOn
              >
                <AutocompleteInput
                  placeholder="Organisation"
                  optionText="company"
                  filterToQuery={(search: any) => ({ company: search })}
                  filledSecondary
                />
              </ReferenceInput>
            )}
            <ReferenceInput
              source="product_id"
              reference="products"
              sort={{ field: "name", order: "ASC" }}
              perPage={1000}
              label="Produit"
            >
              <AutocompleteInput
                placeholder="Produit"
                optionText="name"
                filterToQuery={(search: any) => ({ name: search })}
                filledSecondary
              />
            </ReferenceInput>
            <FormInput
              placeholder="État"
              type="select"
              source="cancelled"
              values={[
                { id: "both", label: "Tous les abonnements" },
                { id: "true", label: "Abonnements annulés" },
                { id: "false", label: "Abonnements en cours" },
              ]}
              filledSecondary
              alwaysOn
            />
            <FormInput label="Référence" hideLabel placeholder="Référence" source="id" filledSecondary />
          </Filter>
        }
        filterDefaultValues={{ cancelled: "both" }}
        actions={<ListActions />}
        empty={false}
        sort={{ field: "periodEnd", order: "ASC" }}
      >
        <SubscriptionsDatagrid identity={identity} {...props} />
      </List>
    </>
  );
};

export default SubscriptionsList;

function getDurationSince(startDate: string) {
  const today = new Date();
  const date = new Date(startDate);
  const yearsDiff = today.getFullYear() - date.getFullYear();
  const monthsDiff = today.getMonth() - date.getMonth();
  const correction = today.getDate() - date.getDate() < 0 ? 0 : 1;

  // +1 to avoid "from 0 month"
  return yearsDiff * 12 + monthsDiff + correction;
}
