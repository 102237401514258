import React, { useCallback } from "react";
import {
  FormDataConsumer,
  useGetIdentity,
  ReferenceInput,
  useNotify,
  CloneButton,
  useGetList,
  useUpdate,
  useRefresh,
  useDataProvider,
} from "react-admin";
import { Edit, SimpleForm } from "@react-admin/ra-rbac";
import { DELAYS } from "../../services/common-utils/constants";
import SmsTextInput from "../../components/inputs/SmsInput";
import { count } from "sms-length";
import useCustomListRedirect from "../../services/hooks/useCustomRedirect";
import PageTitle from "../../components/navigation/PageTitle";
import FormInput from "../../components/form/FormInput";
import { object, string, literal, union, TypeOf, boolean, number, optional, any } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { AutocompleteInput } from "../../components/form/AutocompleteInput";
import { Box } from "@mui/material";
import { CopyIcon } from "lucide-react";
import ExpeditorInput from "../../components/inputs/ExpeditorInput";

const formSchema = object({
  id: any(),
  group_id: string().nullish(),
  name: string().min(1, "Nom du template requis"),
  group_access: boolean(),
  crmGroupId: string().nullish(),
  customExpeditor: boolean().nullish(),
  expeditor: string().nullish(),
  confirmation_message: string().nullish(),
  reminder_message: string().nullish(),
  reminder_delay: number().nullish(),
  showAlert: boolean().nullish(),
  alert_message: string().nullish(),
  alert_delay: number().nullish(),
});

const validateTemplateEdit = (values: any) => {
  let errors = {} as any;
  ["confirmation_message", "reminder_message", "alert_message"].forEach((input: string) => {
    if (values[input]) {
      const smsInfos = count(values[input]);
      if (smsInfos.length > 1377) errors[input] = "Maximum 9 SMS";
    }
  });
  if (values.customExpeditor) {
    if (values.expeditor.length > 11) {
      errors["expeditor"] = "Maximum 11 caractères";
    }
    if (values.expeditor.indexOf(" ") !== -1) {
      errors["expeditor"] = "Espaces interdits";
    }
  }
  return errors;
};

const TemplatesEdit = ({ ...props }) => {
  const { data: identity } = useGetIdentity();
  const redirect = useCustomListRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const [update] = useUpdate();
  const save = useCallback(
    async (values: any) => {
      const { data: previousData } = await dataProvider.getOne("templates", { id: values.id });
      const errors = {} as any;
      if (!values.customExpeditor) {
        values.expeditor = "";
      }
      if (!values.showAlert) {
        values.alert_message = "";
        values.alert_delay = 0;
      }
      delete values.customExpeditor;
      if (["Client", "Indirect"].includes(identity?.status)) {
        values.group_id = identity?.company;
      }
      values.oldCrmGroupId = previousData?.crmGroupId;
      try {
        await update("templates", { id: values.id, data: values, previousData }, { returnPromise: true });
        notify("Modèle SMS modifié avec succès", {
          type: "success",
          messageArgs: { smart_count: 1 },
        });
        redirect("templates");
        refresh();
      } catch (error) {
        if (error?.response?.data?.error.includes("bad_word")) {
          notify(`Un de vos champs contient le mot interdit "${error?.response?.data?.data[0].word}"`, {
            type: "error",
            messageArgs: { smart_count: 1 },
            autoHideDuration: 5000,
          });
        } else {
          notify("Erreur lors de la modification du modèle SMS", {
            type: "error",
            messageArgs: { smart_count: 1 },
          });
        }
        console.log("error", error);
        refresh();
      }
    },
    [dataProvider, identity?.status, identity?.company, update, notify, redirect, refresh]
  );

  return (
    <Edit {...props} mutationMode="optimistic" hasShow={false} actions={<></>}>
      <PageTitle
        text={(record: any) => `Modèle ${record.name}`}
        type="edit"
        resource="templates"
        breadcrumbListLabel="Liste des modèles"
        createButton={<CloneButton icon={<CopyIcon size={18} />} variant="contained" sx={{ px: 2, py: 1 }} />}
      />
      <SimpleForm
        validate={validateTemplateEdit}
        resolver={zodResolver(formSchema)}
        sx={{ position: "relative" }}
        onSubmit={save}
      >
        {["Collaborateur", "Revendeur"].includes(identity?.status) && (
          <ReferenceInput
            required
            source="group_id"
            reference="companies"
            filter={identity?.status === "Revendeur" ? { parentCompany: identity?.company } : {}}
            sort={{ field: "company", order: "ASC" }}
            perPage={1000}
          >
            <AutocompleteInput
              label="Organisation"
              optionText="company"
              filterToQuery={(search: any) => ({ company: search })}
            />
          </ReferenceInput>
        )}
        <FormInput label="Nom du modèle" name="name" required />
        <FormInput
          checkboxLabel="Visible par tout les utilisateurs"
          name="group_access"
          defaultValue={false}
          type="checkbox"
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.group_access === false && (
              <ReferenceInput
                source="crmGroupId"
                reference="groups"
                filter={{
                  company: ["Collaborateur", "Revendeur"].includes(identity?.status)
                    ? formData.group_id
                    : identity?.company,
                }}
                sort={{ field: "name", order: "ASC" }}
                perPage={1000}
              >
                <AutocompleteInput
                  label="Groupe d'accès au modèle"
                  optionText="name"
                  filterToQuery={(search: any) => ({ name: search })}
                  helperText="Seuls les utilisateurs du groupe pourront accéder au modèle"
                />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <FormInput
              name="customExpeditor"
              defaultValue={!!formData.expeditor}
              type="checkbox"
              checkboxLabel="Personnaliser l'expéditeur"
              tooltip="Personnalisez le nom de l'expediteur de votre SMS (ex: Leboncoin, Nike, Adidas, etc). Votre destinataire verra le nom s'afficher à la place du numéro court."
            />
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.customExpeditor && (
              <ExpeditorInput
                label="Expéditeur"
                source="expeditor"
                defaultValue={null}
                helperText="max. 11 caractères alphanumériques"
                inputProps={{ maxLength: 11 }}
              />
            )
          }
        </FormDataConsumer>

        <SmsTextInput source="confirmation_message" defaultValue={null} label="Message de confirmation" />

        <SmsTextInput source="reminder_message" label="Message de rappel" />
        {/* <SelectInput
          defaultValue={1440}
          validate={required()}
          source="reminder_delay"
          label="Délai avant rappel"
          choices={DELAYS}
        /> */}
        <FormInput
          label="Délai avant rappel"
          name="reminder_delay"
          type="select"
          defaultValue={1440}
          values={DELAYS.map((delay: any) => {
            return { label: delay.name, id: delay.id };
          })}
          select
          autoComplete="off"
          required
          placeholder="choisissez..."
        />

        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <FormInput
              checkboxLabel="Envoyer un message d'alerte"
              name="showAlert"
              defaultValue={!!formData.alert_message}
              type="checkbox"
            />
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.showAlert && <SmsTextInput source="alert_message" label="Message d'alerte" />
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.showAlert && (
              <FormInput
                defaultValue={1440}
                label="Délai avant alerte"
                name="alert_delay"
                type="select"
                values={DELAYS.map((delay: any) => {
                  return { label: delay.name, id: delay.id };
                })}
                select
                autoComplete="off"
                required
                placeholder="choisissez..."
              />
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default TemplatesEdit;
