import { Typography, Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNotify } from "react-admin";
import api from "../../services/apiWithAuth";
import Modal from "../../components/modals/Modal";

const actions: any = (subscriptionType: string, totals: any = null) => ({
  // pause: {
  //   buttonText: "Mettre en pause",
  //   buttonColor: "neutral",
  //   modalTitle: "Mise en pause de l'abonnement",
  //   modalText:
  //     "En mettant en pause cet abonnement, les paiements s'interrompront et vous ne pourrez plus utiliser les services auxquels vous avez souscrit.",
  // },
  // unpause: {
  //   buttonText: "Reprendre abonnement",
  //   buttonColor: "neutral",
  //   modalTitle: "Reprise de l'abonnement",
  //   modalText:
  //     "En reprenant cet abonnement, votre abonnement reprendra son cours et vous pourrez réutiliser vos licences.",
  // },
  cancel: {
    buttonText: "Résilier l'abonnement",
    buttonColor: "error",
    modalTitle: "Résiliation l'abonnement",
    modalText:
      subscriptionType === "Licence"
        ? "En résiliant cet abonnement toutes les licences rattachées seront désactivées à la fin de la période de validité. De plus si vous désirez reprendre votre abonnement plus tard la remise fidélité accumulée sera perdue."
        : "En résiliant cet abonnement vous ne recevrez plus vos crédits SMS.",
  },
  uncancel: {
    buttonText: "Réactiver l'abonnement",
    buttonColor: "primary",
    modalTitle: "Réactivation abonnement",
    modalText:
      "En réactivant cet abonnement vous reprendrez votre cycle normal de facturation et vous pourrez de nouveau accéder aux services auxquels ce dernier vous donne droit.",
  },
  resetFromNow: {
    buttonText: "Reprendre l'abonnement",
    buttonColor: "primary",
    modalTitle: "Reprise abonnement à compter d'aujourd'hui",
    modalText: (
      <Typography>
        En reprenant votre abonnement à partir d'aujourd'hui, vous serez facturé immédiatement de{" "}
        <b>{totals?.totalTtc.toFixed(2)}€ TTC</b> sur le moyen de paiement renseigné et vos services reprendront comme
        avant la résiliation. <br /> <br />
        <Typography color="warning">
          Assurez vous que le mode de paiement sur l'abonnement soit valide avant de continuer.
        </Typography>
      </Typography>
    ),
  },
  solveDispute: {
    buttonText: "Résoudre litige et reprendre l'abonnement",
    buttonColor: "primary",
    modalTitle: "Résolution litige et reprise d'abonnement à compter d'aujourd'hui",
    modalText: (
      <Typography>
        En choisissant de résoudre le litige portant sur votre abonnement dû à une erreur de paiement et de reprendre
        l'abonnement à partir d'aujourd'hui, vous serez facturé immédiatement sur le moyen de paiement renseigné du
        montant de votre abonnement <b>+ 7.50€ TTC de frais de résolution de litige.</b> <br /> <br /> Vous aurez
        ensuite de nouveau accés à vos licences/crédits SMS. <br /> <br />
        <Typography color="warning">
          Attention il est préférable de vous assurez que le moyen de paiement choisi est toujours valable pour éviter
          une nouvelle fois les 7.50€ de frais de résolution.
        </Typography>
      </Typography>
    ),
  },
});

// si modif mode de paiement : paymentMethod
const SubscriptionAction = ({ action, identity, subscription }: any) => {
  const [open, setOpen] = useState(false);
  const [newTotals, setNewTotals] = useState(null);
  const notify = useNotify();
  const handleClick = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };

  useEffect(() => {
    if (subscription && ["resetFromNow", "solveDispute"].includes(action)) {
      const asyncCall = async () => {
        try {
          const { data } = await api.post("/utils/getNewSubscriptionTotals", { subscriptionId: subscription.id });
          setNewTotals(data?.totals || null);
        } catch (err) {
          console.log("no new subscription found");
        }
      };
      asyncCall();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription, action]);

  const handleConfirm = async (confirm) => {
    if (confirm) {
      //! maybe not useful to have pausing AND cancellation
      // if (action === "pause") {
      //   try {
      //     await api.post("/utils/pauseStripeSubscription", {
      //       subscriptionId: subscription.stripe_id,
      //     });
      //   } catch (err) {
      //     notify("Erreur lors de la mise en pause de l'abonnement", {
      //       type: "error",
      //       messageArgs: { smart_count: 1 },
      //     });
      //   }
      // }
      // if (action === "unpause") {
      //   if (subscription.stripe_id) {
      //     try {
      //       await api.post("/utils/unpauseStripeSubscription", {
      //         subscriptionId: subscription.stripe_id,
      //       });
      //     } catch (err) {
      //       notify("Erreur lors de la reprise de l'abonnement", {
      //         type: "error",
      //         messageArgs: { smart_count: 1 },
      //       });
      //     }
      //   }
      // }
      if (action === "cancel") {
        try {
          await api.post("/utils/cancelSubscription", {
            subscriptionId: subscription.id,
            stripeId: subscription.stripe_id,
          });
        } catch (err) {
          notify("Erreur lors l'annulation de l'abonnement", {
            type: "error",
            messageArgs: { smart_count: 1 },
          });
        }
      }
      if (action === "uncancel") {
        try {
          await api.post("/utils/uncancelSubscription", {
            subscriptionId: subscription.id,
            stripeId: subscription.stripe_id,
          });
        } catch (err) {
          notify("Erreur lors de la réactivation de l'abonnement", {
            type: "error",
            messageArgs: { smart_count: 1 },
          });
        }
      }
      if (action === "resetFromNow") {
        // create stripe sub but instead of putting first bill at periodEnd, make it start from now
        // if stripe sub already exist we need to create another one because it is not possible to reset cancelled subscriptions
        try {
          await api.post("/utils/createStripeSubscription", {
            subscription,
            paymentMethodId: subscription.paymentMethod.id,
            resetMode: true,
          });
        } catch (err) {
          notify("Erreur lors de la reprise d'abonnement", {
            type: "error",
            messageArgs: { smart_count: 1 },
          });
        }
      }
      if (action === "solveDispute") {
        try {
          await api.post("/utils/createStripeSubscription", {
            subscription,
            paymentMethodId: subscription.paymentMethod.id,
            resetMode: true,
            chargeDispute: true,
          });
        } catch (err) {
          notify("Erreur lors de la reprise de l'abonnement.", {
            type: "error",
            messageArgs: { smart_count: 1 },
          });
        }
      }
    }
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        color={action === "modifyQuantity" ? "primary" : actions(subscription.type)[action].buttonColor}
        variant="contained"
      >
        <Typography variant="medium_sm">{actions(subscription.type)[action].buttonText}</Typography>
      </Button>
      <Modal
        title={actions(subscription.type)[action].modalTitle}
        content={actions(subscription.type, newTotals)[action].modalText}
        open={open}
        handleConfirm={handleConfirm}
      />
    </>
  );
};

export default SubscriptionAction;
